<template>
  <div>
    <gl-map
      ref="mapRef"
      :attr-control-visible="true"
      :init-map-position="options"
      :logo-control="logoControl"
      :attr-control="attrControl"
    />
  </div>
</template>

<script>
import GlMap from '@anagraph/gl-map'
import { usePopup, removePopup } from '../commons/lib/popupHelper'
import { useToggleLayers } from '@anagraph/composable-map'
import { shallowRef, onMounted, toRef, watch, ref } from 'vue'
import AttributionControl from '@/components/mapbox-controls/attributionControl'
import LogoControl from '@/components/mapbox-controls/logoControl'
import {
  setMapPosition,
  getMapPosition,
  updateMapPosition
} from '@/components/mapPosition'

export default {
  components: {
    GlMap
  },
  props: {
    token: {
      type: String,
      default: null
    },
    layersConfig: {
      type: Array,
      default: () => []
    },
    visibleLayers: {
      type: Array,
      default: () => []
    },
    filter: {
      type: Array,
      default: () => []
    }
  },
  emits: ['loaded'],
  setup(props, { emit }) {
    const mapRef = shallowRef(null)
    const visibleLayersRef = toRef(props, 'visibleLayers')
    const attrControl = AttributionControl
    const logoControl = LogoControl
    const selectedFeatureId = ref(null)
    useToggleLayers(mapRef, props.layersConfig, visibleLayersRef)
    setMapPosition()
    onMounted(() => {
      const map = mapRef.value.map
      usePopup(map)
      map.on('load', () => {
        props.layersConfig.forEach(layer => {
          map.on('click', layer.id, e => {
            if (e.features.length > 0) {
              if (selectedFeatureId.value !== null) {
                map.setFeatureState(
                  {
                    source: `${layer.id}-source`,
                    id: selectedFeatureId.value,
                    sourceLayer: 'jd.taxes_foncieres_agg_h3_r9_mercator'
                  },
                  { hover: false }
                )
              }
              selectedFeatureId.value = e.features[0].id
              map.setFeatureState(
                {
                  source: `${layer.id}-source`,
                  id: selectedFeatureId.value,
                  sourceLayer: 'jd.taxes_foncieres_agg_h3_r9_mercator'
                },
                { hover: true }
              )
            }
          })
        })
        watch(visibleLayersRef, () => {
          props.layersConfig.forEach(layer => {
            map.setFeatureState(
              {
                source: `${layer.id}-source`,
                id: selectedFeatureId.value,
                sourceLayer: 'jd.taxes_foncieres_agg_h3_r9_mercator'
              },
              { hover: false }
            )
          })
          removePopup()
        })
        emit('loaded', true)
      })
      map.on('idle', () => {
        const map = mapRef.value.map
        updateMapPosition(map, visibleLayersRef.value)
        /* const features = map.queryRenderedFeatures({
          layers: visibleLayersRef.value
        }) */
        emit('loaded', true)
      })
    })
    const transformRequest = (url, resourceType) => {
      if (!process.env.VUE_APP_PROTECTED_TILES_URL) return
      if (
        resourceType === 'Tile' &&
        url.startsWith(process.env.VUE_APP_PROTECTED_TILES_URL)
      ) {
        return {
          url: url,
          headers: { Authorization: `Bearer ${props.token}` }
        }
      }
    }
    return {
      mapRef,
      options: {
        ...getMapPosition(),
        maxBounds: [-74.4, 45, -73, 46],
        minZoom: 10,
        maxZoom: 14,
        transformRequest
      },
      attrControl,
      logoControl
    }
  }
}
</script>

<style lang="scss" scoped></style>
