export default {
  firebaseConfig: {
    apiKey: 'AIzaSyAC-lHbofL5RNX4SBUa8EKL5BRpFvOSIlA',
    authDomain: 'urban-dev-29e2f.firebaseapp.com',
    projectId: 'urban-dev-29e2f',
    storageBucket: 'urban-dev-29e2f.appspot.com',
    messagingSenderId: '636243262461',
    appId: '1:636243262461:web:8b3b408c4a272529a43794'
  }
}
