export default [
  {
    id: 'urban-metrics-total',
    label: 'Impôt Total',
    source: {
      type: 'vector',
      tiles: [
        'https://warehouse.anagraph.io/jd.taxes_foncieres_agg_h3_r9_mercator/{z}/{x}/{y}.pbf'
      ]
    },
    style: {
      type: 'fill-extrusion',
      'source-layer': 'jd.taxes_foncieres_agg_h3_r9_mercator',
      paint: {
        'fill-extrusion-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          '#3FEFA1',
          [
            'step',
            ['to-number', ['get', 'impot_tot']],
            '#feffd2',
            1000000,
            '#fee791',
            2500000,
            '#ffbe5c',
            10000000,
            '#fe8840',
            20000000,
            '#f23b2a',
            40000000,
            '#c90a21',
            60000000,
            '#800026',
            80000000,
            'black'
          ]
        ],
        'fill-extrusion-height': [
          'interpolate',
          ['linear'],
          ['zoom'],
          4,
          0,
          13.05,
          ['/', ['get', 'impot_tot'], 4000]
        ],
        'fill-extrusion-opacity': 0.9
      }
    },
    before: 'place_villages'
  },
  {
    id: 'urban-metrics-residentiel',
    label: 'Impôt Résidentiel',
    source: {
      type: 'vector',
      tiles: [
        'https://warehouse.anagraph.io/jd.taxes_foncieres_agg_h3_r9_mercator/{z}/{x}/{y}.pbf'
      ]
    },
    style: {
      type: 'fill-extrusion',
      'source-layer': 'jd.taxes_foncieres_agg_h3_r9_mercator',
      paint: {
        'fill-extrusion-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          '#3FEFA1',
          [
            'step',
            ['to-number', ['get', 'impot_residentiel']],
            '#f4f5dc',
            50000,
            '#feffd2',
            243798.13,
            '#fee791',
            639608.52,
            '#ffbe5c',
            1080567.74,
            '#fe8840',
            1665210.06,
            '#f23b2a',
            2564323.76,
            '#c90a21',
            4692477.36,
            '#800026',
            9218053.74,
            'black'
          ]
        ],
        'fill-extrusion-height': [
          'interpolate',
          ['linear'],
          ['zoom'],
          4,
          0,
          13.05,
          ['/', ['get', 'impot_residentiel'], 3500]
        ],
        'fill-extrusion-opacity': 0.8
      }
    },
    before: 'place_villages'
  }
]

/* properties:
fid: 2228
impot_autres: 0
impot_residentiel: 20673
impot_tot: 20673 */
