<template>
  <div class="radios-selector">
    <div v-for="{ id, label } in items" :key="id" class="radios-selector__item">
      <input :id="id" v-model="picked" type="radio" :value="id" />
      <label :class="[{ active: id === selected }]" :for="id">{{
        label
      }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [
        { id: 'one', label: 'One' },
        { id: 'two', label: 'Two' }
      ]
    },
    selected: {
      type: String,
      default: 'one'
    }
  },
  emits: ['update:selected'],
  data() {
    return {
      picked: this.selected || 'one'
    }
  },
  watch: {
    picked(val) {
      this.$emit('update:selected', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.radios-selector {
  position: absolute;
  top: 16px;
  left: 16px;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: left;
  justify-content: left;
  row-gap: 10px;
  @media screen and (max-width: 425px) {
    top: 10px;
    left: 10px;
    row-gap: 8px;
  }
  &__item {
    input {
      display: none;
    }
    label {
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;
      display: inline-block;
      background-color: #e2edf5;
      border: 1px solid #000;
      border-radius: 4px;
      width: 102px;
      padding: 8px 12px;
      box-shadow: 0px 6px 8px -1px rgba(0, 0, 0, 0.4);
      &:hover {
        background-color: #d2e4f1;
      }
    }
  }
}

.active {
  color: #e2f6ee;
  background-color: #000 !important;
}
</style>
