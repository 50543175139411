import { useRoute, useRouter } from 'vue-router'

let route
let router
let defaultCenter
let defaultZoom
let defaultBearing
let defaultPitch

// Sets starting map position according to url
export const setMapPosition = () => {
  route = useRoute()
  router = useRouter()
  const urlLat = route.query.lat
  const urlLong = route.query.long
  defaultCenter =
    urlLat && urlLong
      ? { lng: urlLong, lat: urlLat }
      : { lng: urlLong || -73.6921, lat: urlLat || 45.5338 }

  defaultZoom = route.query.zoom || 10.57
  defaultBearing = route.query.bearing || -28.8
  defaultPitch = route.query.pitch || 60
}

// Returns the values of map position to be passed to map component
export const getMapPosition = () => {
  return {
    zoom: defaultZoom,
    bearing: defaultBearing,
    pitch: defaultPitch,
    center: defaultCenter
  }
}

// Updates url when we move in map
export const updateMapPosition = (map, layer) => {
  const mapZoom = map.getZoom().toFixed(2)
  const mapBearing = map.getBearing().toFixed(1)
  const mapPitch = map.getPitch().toFixed(0)
  const mapLat = map.getCenter().lat.toFixed(4)
  const mapLong = map.getCenter().lng.toFixed(4)
  router.replace({
    path: route.path,
    query: {
      zoom: mapZoom,
      bearing: mapBearing,
      pitch: mapPitch,
      lat: mapLat,
      long: mapLong,
      layer: layer[0].split(' ').join('-')
    }
  })
}
