import mapboxgl from 'mapbox-gl'
import * as turf from '@turf/turf'
import { ref } from 'vue'
import popupConfig from './popupConfig.json'

const popupRefs = popupConfig.map(() => {
  const popupRef = ref(null)
  return popupRef
})

export const usePopup = map => {
  popupConfig.forEach((layer, i) => {
    map.on('mouseenter', layer.layerName, () => {
      map.getCanvas().style.cursor = 'pointer'
    })
    map.on('mouseleave', layer.layerName, () => {
      map.getCanvas().style.cursor = ''
    })
    map.on('click', layer.layerName, e => {
      let htmlContent = ''

      layer.layerProps.forEach(layerProp => {
        const featureValue = e.features[0].properties[layerProp.prop]
        let value
        switch (layerProp.unit) {
          case '$':
            value = featureValue.toLocaleString('fr-Ca')
            break
          default:
            value = featureValue
        }
        htmlContent += `<p><b>${layerProp.label}:</b> ${value} ${layerProp.unit}</p>`
      })
      var polygon = turf.polygon(e.features[0].geometry.coordinates)
      var center = turf.centerOfMass(polygon)
      popupRefs[i].value = new mapboxgl.Popup()
        .setLngLat(center.geometry.coordinates)
        .setHTML(htmlContent)
        .addTo(map)
      return popupRefs[i].value
    })
  })
}

export const getPopup = () => {
  return popupRefs
}

export const removePopup = () => {
  getPopup().forEach(popupRef => {
    if (popupRef.value) popupRef.value.remove()
  })
}
