import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    query: {
      zoom: 10.57,
      bearing: -28.8,
      pitch: 60,
      lat: 45.5338,
      long: -73.6921,
      layer: 'urban metrics total'
    },
    meta: { public: true }
  }
]

const router = createRouter({
  history: createWebHistory(),
  // base: process.env.BASE_URL,
  routes
})

export default router
