<template>
  <div class="home">
    <left-panel-layout :is-left-panel-open="mapLoaded" class="app-layout">
      <template #left-panel>
        <div v-if="mapLoaded">
          <div class="left-panel-content-container">
            <h2 class="title">Title</h2>
          </div>
        </div>
        <div v-else>loading ...</div>
      </template>
      <template #map>
        <Map
          :token="token"
          :layers-config="layersConfig"
          :visible-layers="[selected]"
          @loaded="mapLoaded = true"
        />
      </template>
    </left-panel-layout>
    <radio-selector
      v-model:selected="selected"
      :items="layersConfig.map(layer => ({ id: layer.id, label: layer.label }))"
    />
    <!-- <panel class="home__right-panel" /> -->
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import LeftPanelLayout from '@anagraph/left-panel-layout'
// import CategoryWidget from '@anagraph/category-widget'
import Map from '@/components/map'
import layersConfigurations from '@/layer-configurations'
import layerSymbologies from '@/layer-symbologies'
import RadioSelector from '@/components/radio-selector'
import { addLayersSymbologies } from '@/symbologiesHelper.js'
// import Panel from '@anagraph/panel'
import { getToken } from '@/commons/auth/firebase-auth'
import { computed, onMounted, ref } from 'vue'

export default {
  name: 'Home',
  components: {
    LeftPanelLayout,
    Map,
    RadioSelector
    // Panel
  },
  setup() {
    const route = useRoute()

    const layersConfig = addLayersSymbologies(
      layersConfigurations,
      layerSymbologies
    )
    const mapLoaded = ref(false)
    const token = ref(null)
    const widgetRef = ref(null)
    const selected = ref(
      route.query.layer
        ? route.query.layer.split(' ').join('-')
        : layersConfigurations[0].id
    )
    const visibleLayers = computed(() => [selected.value])
    onMounted(async () => {
      token.value = await getToken()
    })
    return {
      mapLoaded,
      token,
      layersConfig,
      selected,
      widgetRef,
      visibleLayers
    }
  }
}
</script>
<style lang="scss">
.home {
  .panel {
    display: none;
  }
  .left-panel {
    &__toggler {
      display: none !important;
    }
  }
}
.mapboxgl-popup {
  bottom: 20px;
  top: -3px;
  &-content {
    padding: 10px !important;
    text-align: left;
  }
  p {
    font-size: 12px !important;
    margin: 5px 0 0 0;
  }
}
</style>
